import React from "react";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const Seo = ({ title, description, keywords, slug }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          siteUrl
        }
      }
    }
  `);

  return (
    <Helmet htmlAttributes={{ lang: `en-US` }}>
      <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
      <title>{title || data.site.siteMetadata.title}</title>
      <meta
        name="description"
        content={description || data.site.siteMetadata.description}
      />
      <link
        hreflang="en-us"
        href={`${data.site.siteMetadata.siteUrl}${slug || ""}`}
      />
      <link
        rel="canonical"
        href={`${data.site.siteMetadata.siteUrl}${slug || ""}`}
      />
      <meta name="keywords" content={keywords || ""} />
      <meta property="twitter:card" data-page-subject="true" value="summary" />
      <meta property="twitter:site" data-page-subject="true" value="@Tomedes" />
      <meta
        property="og:site_name"
        data-page-subject="true"
        content="Lextranscribe"
      />
      <meta property="og:locale" data-page-subject="true" content="en_US" />
      <meta
        property="og:title"
        data-page-subject="true"
        content={title || data.site.siteMetadata.title}
      />
      <meta
        property="og:keyword"
        data-page-subject="true"
        content={keywords || ""}
      />
      <meta property="robots" content="index,follow" />
      <meta
        property="og:description"
        data-page-subject="true"
        content={description || data.site.siteMetadata.description}
      />
      <meta property="og:type" data-page-subject="true" content="article" />
      <meta
        property="og:url"
        data-page-subject="true"
        content={`${data.site.siteMetadata.siteUrl}${slug || ""}`}
      />
    </Helmet>
  );
};

export default Seo;
